<template>
    <div>
        <div class="is-flex align-items is-justify-content-space-between">
            <div class="searchbox control has-icons-right">
                <input class="input" type="text" placeholder="Filtrar clientes" @keyup="queryClient($event.target.value)">
                <span class="icon is-small is-right">
                    <font-awesome-icon icon="search" />
                </span>
            </div>
            <button id="addClient" class="button is-primary" @click="showAddModal()">Adicionar cliente</button>
        </div>
        <table class="table is-narrow">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>ID</th>
                        <th>Data de criação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="client in exhibitClients" :key="client.id" :id="client.id" >
                            <td>{{client.name}}</td>
                            <td>{{client.id}}</td>
                            <td>{{client.created_at | processDate}}</td>
                            <td>
                                <button class="button is-success" @click="showEditModal(client.id)"><font-awesome-icon icon="edit" /></button><button class="button is-danger" @click="showDeactivateModal(client.id)"><font-awesome-icon icon="power-off" /></button>
                            </td>
                    </tr>
                </tbody>
        </table>
        <nav class="pagination" role="navigation" aria-label="pagination">
            <ul class="pagination-list">
                <li v-for="page in pages" :key="page">
                    <a class="pagination-link" @click="updatePage(page)">{{page}}</a>
                </li>
            </ul>
        </nav>
        <div id="deactivateModal" class="modal">
                    <div class="modal-background"></div>
                    <div class="modal-content">
                        <div class="card">
                            <header class="card-header">
                                <p class="card-header-title">
                                Deseja desativar esse cliente?
                                </p>
                            </header>
                            <div class="card-content">
                                <div class="content">
                                    Confirme se deseja realmente desativar esse cliente.
                                </div>
                            </div>
                            <footer class="card-footer">
                                <a href="javascript:void(0)" class="card-footer-item" @click="deactivateClient">Desativar</a>
                                <a href="javascript:void(0)" class="card-footer-item" @click="hideDeactivateModal">Cancelar</a>
                            </footer>
                            </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="hideDeactivateModal"></button>
        </div>
        <div id="editClient" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Editando Cliente</p>
                <button class="delete" aria-label="close" @click="hideEditModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Nome</label>
                        <div class="control">
                            <input id="clientName" class="input editable" type="text" placeholder="Text input" v-model="editClient.name" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">ID</label>
                        <div class="control">
                            <input id="clientId" class="input" type="text" placeholder="Text input" v-model="editClient.id" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Records Prefix</label>
                        <div class="control">
                            <input id="RcdsPrefix" class="input editable" type="text" placeholder="(NULL)" v-model="editClient.gc_storage_rcds_prefix" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Goals Prefix</label>
                        <div class="control">
                            <input id="GoalsPrefix" class="input editable" type="text" placeholder="(NULL)" v-model="editClient.gc_storage_goals_prefix" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Facebook Records Prefix</label>
                        <div class="control">
                            <input id="FBPrefix" class="input editable" type="text" placeholder="(NULL)" v-model="editClient.gc_storage_fcb_prefix" disabled>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <input id="fbpagesSwitch" v-model="editClient.facebookpages" type="checkbox" name="fbpagesSwitch" class="switch is-rounded editable" disabled>
                                <label for="fbpagesSwitch">Facebook Pages</label>
                            </div>
                            <div class="field">
                                <input id="fbpostsSwitch" v-model="editClient.facebook_posts" type="checkbox" name="fbpostsSwitch" class="switch is-rounded editable" disabled>
                                <label for="fbpostsSwitch">Facebook Posts</label>
                            </div>
                            <div class="field">
                                <input id="igpostsSwitch" v-model="editClient.instagramposts" type="checkbox" name="igpostsSwitch" class="switch is-rounded editable" disabled>
                                <label for="igpostsSwitch">Instagram Posts</label>
                            </div>
                            <div class="field">
                                <input id="igprofileSwitch" v-model="editClient.instagramprofile" type="checkbox" name="igprofileSwitch" class="switch is-rounded editable" disabled>
                                <label for="igprofileSwitch">Instagram Profile</label>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <input id="lkdpageSwitch" v-model="editClient.linkedinpage" type="checkbox" name="lkdpageSwitch" class="switch is-rounded editable" disabled>
                                <label for="lkdpageSwitch">LinkedIn Page</label>
                            </div>
                            <div class="field">
                                <input id="lkdpostsSwitch" v-model="editClient.linkedinposts" type="checkbox" name="lkdpostsSwitch" class="switch is-rounded editable" disabled>
                                <label for="lkdpostsSwitch">LinkedIn Posts</label>
                            </div>
                            <div class="field">
                                <input id="lkdvideosSwitch" v-model="editClient.linkedinvideos" type="checkbox" name="lkdvideosSwitch" class="switch is-rounded editable" disabled>
                                <label for="lkdvideosSwitch">LinkedIn Videos</label>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button id="editClientButton" class="button is-warning" @click="startDraft">Editar</button>
                    <button id="saveClientButton" class="button is-success" @click="updateClient" style="display: none;">Salvar</button>
                    <button class="button" @click="hideEditModal">Cancel</button>
                </footer>
            </div>
        </div>
        <div id="addClientModal" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Adicionando Cliente</p>
                <button class="delete" aria-label="close" @click="hideAddModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Nome</label>
                        <div class="control">
                            <input id="addClientName" class="input" type="text" placeholder="Nome do cliente" v-model="addClient.name">
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <input id="fbpagesAddSwitch" v-model="addClient.fbpages" type="checkbox" name="fbpagesSwitch" class="switch is-rounded">
                                <label for="fbpagesAddSwitch">Facebook Pages</label>
                            </div>
                            <div class="field">
                                <input id="fbpostsAddSwitch" v-model="addClient.fbposts" type="checkbox" name="fbpostsSwitch" class="switch is-rounded">
                                <label for="fbpostsAddSwitch">Facebook Posts</label>
                            </div>
                            <div class="field">
                                <input id="igpostsAddSwitch" v-model="addClient.igposts" type="checkbox" name="igpostsSwitch" class="switch is-rounded">
                                <label for="igpostsAddSwitch">Instagram Posts</label>
                            </div>
                            <div class="field">
                                <input id="igprofileAddSwitch" v-model="addClient.igprofile" type="checkbox" name="igprofileSwitch" class="switch is-rounded">
                                <label for="igprofileAddSwitch">Instagram Profile</label>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <input id="lkdpageAddSwitch" v-model="addClient.lkdpage" type="checkbox" name="lkdpageSwitch" class="switch is-rounded">
                                <label for="lkdpageAddSwitch">LinkedIn Page</label>
                            </div>
                            <div class="field">
                                <input id="lkdpostsAddSwitch" v-model="addClient.lkdposts" type="checkbox" name="lkdpostsSwitch" class="switch is-rounded">
                                <label for="lkdpostsAddSwitch">LinkedIn Posts</label>
                            </div>
                            <div class="field">
                                <input id="lkdvideosAddSwitch" v-model="addClient.lkdvideos" type="checkbox" name="lkdvideosSwitch" class="switch is-rounded">
                                <label for="lkdvideosAddSwitch">LinkedIn Videos</label>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="createClient">Salvar</button>
                    <button class="button" @click="hideAddModal">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created(){
        var config ={
            headers:{
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        }
        axios.get("https://api.addata.me/clients/active",config).then(res =>{
            //console.log(res)
            this.allClients = res.data.data;
            this.pages = Math.ceil(this.allClients.length / 10)
            this.updatePage(1);
        }).catch(err =>{
            console.log(err)
        })
    },
    data(){
        return{
            allClients: [],
            exhibitClients: [],
            pages: 1,
            currentPage: 1,
            addClient:{name: '', fbpages: false, fbposts: false, igprofile: false, igposts: false, lkdpage: false, lkdposts: false, lkdvideos: false},
            editClient: {},
            deactivateClientId:0
        }
    },
    name: 'ClientsList',
    methods:{
        showEditModal(id){
            var el = document.getElementById('editClient')
            el.classList.add("is-active");
            var client = this.exhibitClients.filter(client => client.id == id);
            this.editClient = client[0];
            
            if(this.editClient.gc_storage_rcds_prefix == null){
                document.getElementById('RcdsPrefix').classList.remove("editable")
            }
            if(this.editClient.gc_storage_goals_prefix == null){
                document.getElementById('GoalsPrefix').classList.remove("editable")
            }
            if(this.editClient.gc_storage_fcb_prefix == null){
                document.getElementById('FBPrefix').classList.remove("editable")
            }

        },
        hideEditModal(){
            var el = document.getElementById('editClient')
            el.classList.remove("is-active");
            let els = document.getElementsByClassName('editable');
            els.forEach(element => {
                element.disabled = true;
            });
            document.getElementById('editClientButton').style.display = 'flex';
            document.getElementById('saveClientButton').style.display = 'none';
            if(this.editClientRcds == null){
                document.getElementById('RcdsPrefix').classList.add("editable")
            }
            if(this.editClientGoals == null){
                document.getElementById('GoalsPrefix').classList.add("editable")
            }
            if(this.editClientFcb == null){
                document.getElementById('FBPrefix').classList.add("editable")
            }
        },
        showDeactivateModal(id){
            var el = document.getElementById('deactivateModal')
            el.classList.add("is-active");
            this.deactivateClientId = id;
        },
        hideDeactivateModal(){
            var el = document.getElementById('deactivateModal')
            el.classList.remove("is-active");
        },
        startDraft(){
            let els = document.getElementsByClassName('editable');
            els.forEach(element => {
                element.disabled = false;
            });
            document.getElementById('editClientButton').style.display = 'none';
            document.getElementById('saveClientButton').style.display = 'flex';
        },
        showAddModal(){
            document.getElementById('addClientModal').classList.add("is-active");
        },
        hideAddModal(){
            document.getElementById('addClientModal').classList.remove("is-active");
            this.addClient = {name: '', fbpages: false, fbpost: false, igprofile: false, igposts: false, lkdpage: false, lkdposts: false, lkdvideos: false};
        },
        createClient(){
            var headers =  {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var clientData = this.addClient;
            axios.post("https://api.addata.me/clients",clientData,{
                headers: headers
            }).then(response =>{
                console.log(response.data)
                this.hideAddModal();
                axios.get("https://api.addata.me/clients/active",{
                    headers: headers
                }).then(res =>{
                    //console.log(res)
                    this.allClients = res.data.data;
                    this.pages = Math.ceil(this.allClients.length / 10)
                    this.updatePage(this.currentPage);
                }).catch(err =>{
                    console.log(err)
                })
            }).catch(err =>{
                console.log(err);
                this.hideAddModal();
            })
        },
        updateClient(){
            var headers =  {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var data = this.editClient;
            axios.put("https://api.addata.me/clients",data,{
                headers: headers
            }).then(response =>{
                console.log(response)
            }).catch(err =>{
                console.log(err)
                })
            this.hideEditModal();
        },
        deactivateClient(){
            var config ={
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }
            axios.delete("https://api.addata.me/clients/" + this.deactivateClientId,config).then(() =>{
                this.allClients = this.allClients.filter(c => c.id != this.deactivateClientId);
                this.exhibitClients = this.exhibitClients.filter(c => c.id != this.deactivateClientId);
                this.hideDeactivateModal();
            }).catch(err =>{
                console.log(err)
            })
        },
        updatePage(pageNumber){
            var pageLinks = document.getElementsByClassName('pagination-link');
            pageLinks[this.currentPage -1].classList.remove('is-current');
            pageLinks[pageNumber -1].classList.add('is-current');
            this.currentPage = pageNumber;
            this.exhibitClients = this.allClients.slice((pageNumber * 10)-10,pageNumber * 10);
        },
        queryClient(query){
            if(query == ''){
                this.exhibitClients = this.allClients.slice((this.currentPage * 10)-10,this.currentPage * 10);
            } else{
                this.exhibitClients = this.allClients.filter(client => client.name.toLowerCase().includes(query.toLowerCase()))
            }
        }
    },
    props: {
        isAdmin: Boolean
    },
    filters: {
        processDate: function(date){
            return date.split('T')[0]
        }
    }
}
</script>
<style scoped>
table{
    margin-top: 3%;
    margin-left: 2%;
    width: 90%;
}
.searchbox{
    max-width: 45%;
    margin-left: 2%;
}
.pagination{
    margin-left: 2%;
}
#selectRole{   
    display: none;
}
.button.is-info{
    margin-top: 1rem;
}
.field .button.is-success{
    margin-top: 1rem;
}
#addUser{
    margin-left: 3%;
}
</style>