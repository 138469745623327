<template>
    <div>
        <h1> Painel adm!</h1>
            <table class="table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Cargo</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{user.name}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.role | processRole}}</td>
                            <td>
                                <router-link :to="{name: 'Edit',params:{id:user.id}}"><button class="button is-success">Editar</button></router-link><button class="button is-danger" @click="showModal(user.id)">Deletar</button>
                            </td>
                        </tr>
                </tbody>
            </table>
            <div class="modal">
                <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                            Deseja deletar esse usuário?
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content">
                                Escolha se deseja realmente deletar esse usuário.
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item" @click="deleteUser">Deletar</a>
                            <a href="#" class="card-footer-item" @click="hideModal">Cancelar</a>
                        </footer>
                        </div>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="hideModal"></button>
            </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created(){
        var config ={
            headers:{
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        }
        axios.get("https://api.addata.me/user",config).then(res =>{
            //console.log(res)
            this.users = res.data;
        }).catch(err =>{
            console.log(err)
        })
        console.log()
    },
    data(){
        return{
            users: [],
            deleteUserId: 0
        }
    },
    methods:{
        showModal(id){
            var el = document.getElementsByClassName('modal')[0]
            el.classList.add("is-active");
            this.deleteUserId = id;
        },
        hideModal(){
            var el = document.getElementsByClassName('modal')[0]
            el.classList.remove("is-active");
        },
        deleteUser(){
            var config ={
                    headers:{
                        Authorization: "Bearer " + localStorage.getItem('token')
                    }
                }
            axios.delete("https://api.addata.me/user/" + this.deleteUserId, config).then(()=>{
                this.hideModal();
                this.users = this.users.filter(u => u.id != this.deleteUserId);
            }).catch(err=>{
                console.log(err);
                this.hideModal();
            })
        }
    },
    filters: {
        processRole: function(value){
            if(value==0){
                return "Colaborador"
            }else if(value==1){
                return "Administrador"
            }
        }
    }
}
</script>
<style scoped>
    .button.is-success{
        right: 5%
    }
</style>