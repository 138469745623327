<template>
    <div class="hero is-fullheight is-light">
        <div id="changePassword-box" class="columns is-centered">
            <form id="changePassword-form" class="box column is-one-fifth">
                <div class="has-text-centered p-4">
                    <img src="/pics/addata2.png" style="max-height: 10rem">
                    <p class="title is-4 has-text-weight-bold p-1">Renovação de Senha</p>
                    <p class="subtitle is-6 is-italic">Digite uma nova senha e confirme.</p>
                </div>
                <div v-if="err != undefined">
                    <div class="notification is-danger">
                        <!--<button class="delete"></button>-->
                        {{err}}
                    </div>
                </div>
                <div class="field">
                    <label class="label">Nova senha</label>
                    <div class="control has-icons-left">
                        <input class="input" type="password" placeholder="********" v-model="password">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="lock" />
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Repetir senha</label>
                    <div class="control has-icons-left">
                        <input class="input" type="password" placeholder="********" v-model="confirmPassword">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="lock" />
                        </span>
                    </div>
                </div>
                <div class="field" @click="changePassword">
                    <button class="button is-info">Salvar</button>
                </div>
                <article id="mensagem" class="message is-primary">
                    <div id="corpoMensagem" class="message-body">
                        Nova senha cadastrada!
                    </div>
                </article>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            password: '',
            confirmPassword: '',
            token: this.$route.query.t,
            err: undefined
        }
    },
    methods: {    
        changePassword(){
            if(this.password!=this.confirmPassword){
                alert("As senhas não batem!")
            } else {
                axios.post("https://api.addata.me/changepassword",{
                    password: this.password,
                    token: this.token
                }).then((response)=>{
                    console.log(response);
                    document.getElementById('mensagem').style.display = "block";
                    setTimeout(()=>{
                        document.getElementById('mensagem').classList.add("hide");
                        location.reload();
                    },2000)
                }).catch((err)=>{
                    console.log(err.response.data);
                    document.getElementById('mensagem').style.display = "block";
                    document.getElementById('mensagem').classList.remove('is-primary');
                    document.getElementById('mensagem').classList.add('is-danger');
                    document.getElementById('corpoMensagem').innerText = "Erro ao salvar senha";
                    setTimeout(()=>{
                        document.getElementById('mensagem').classList.add("hide");
                    },1200)
                    setTimeout(()=>{
                        location.reload();
                    },1900)
                })
            }
        }
    },
    mounted: function(){
        document.getElementById('changePassword-form').addEventListener("submit", function(event){
            event.preventDefault()
        });

    }
}
</script>
<style scoped>
#changePassword-box{
    margin-top: 10rem;
}
#mensagem{
    display: none;
}
.message {
  margin-top: 1rem;
  width: 360px;
  transition: all 2s;
}

.message.hide {
  width: 100px;
  opacity: 0;
  font-size: 0.5rem;
}
</style>