import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/Register.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Users from '../views/Users.vue'
import Edit from '../views/Edit.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import Chart from '../views/Chart.vue'
import axios from 'axios'

function AdminAuth(to, from, next){

  if(localStorage.getItem('token')!=undefined){
    var config ={
      headers:{
        Authorization: "Bearer " + localStorage.getItem('token')
      }
    }
    axios.post("https://api.addata.me/validate",{},config).then(() => {
      //console.log(req)
      if(to.name == "Login"){
        next("/admin/dashboard")
      }else if(to.name == "Home"){
        next("/admin/dashboard")
      }else{
        next()
      }
    }).catch(err =>{
      console.log(err.response.data) 
      next("/login")
    })
  }else if(to.name == "Login"){
    console.log("Token não identificado.")
    next()
  } else{
    next("/login");
  }
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login,
    beforeEnter: AdminAuth
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path: '/register',
    name: 'Register',
    component: Register
  },{
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: AdminAuth,
    meta: {
      title: 'Login',
      metaTags: [
        {
          name: 'description',
          content: 'Login Page.'
        },
        {
          property: 'og:description',
          content: 'Login Page'
        }
      ]
    }
  },{
    path: '/admin/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      metaTags: [
        {
          name: 'description',
          content: 'Reset Password Page.'
        },
        {
          property: 'og:description',
          content: 'Reset Password Page'
        }
      ]
    }
  },{
    path: '/admin/users',
    name: 'Users',
    component: Users,
    beforeEnter: AdminAuth
  },{
    path: '/admin/edit/:id',
    name: 'Edit',
    component: Edit,
    beforeEnter: AdminAuth
  },{
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      title: 'Painel Administrativo',
      metaTags: [
        {
          name: 'description',
          content: 'Painel Administrativo do Addata'
        },
        {
          property: 'og:description',
          content: 'Painel Administrativo do Addata'
        }
      ]
    },
    beforeEnter: AdminAuth
  },{
    path: '/dashboard/*',
    name: 'Dashboard',
    component: Dashboard
  },{
    path: '/chart',
    name: 'Chart',
    component: Chart
  },{
    path: '*',
    name: 'catchAll',
    component: Home,
    beforeEnter: AdminAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
