<template>
    <div class="is-primary">
        <NavBar is-logged/>
        
        <div id="tables-box" class="columns">
            <div class="column is-one-quarter"></div>
            <div id="mainColumn" class="box column is-three-fifths">
                <div class="tabs is-boxed">
                    <ul>
                        <!--<li id='manageAccount' @click="changeTabs('manageAccount')">
                            <a>
                                <span>Minha Conta</span>
                            </a>
                        </li>-->
                        <li id='manageTables' class="is-active" @click="changeTabs('manageTables')">
                            <a>
                                <span>Atualizar Tabelas</span>
                            </a>
                        </li>
                        <li id='manageUsers' @click="changeTabs('manageUsers')">
                            <a>
                                <span>Usuários</span>
                            </a>
                        </li>
                        <li id='manageCompanies' @click="changeTabs('manageCompanies')">
                            <a>
                                <span>Empresas</span>
                            </a>
                        </li>
                        <li id='manageDashboards' @click="changeTabs('manageDashboards')">
                            <a>
                                <span>Dashboards</span>
                            </a>
                        </li>
                        
                    </ul>
                </div>
                <!--<div id="manageAccount-tab-block" style="display: none">
                    <div id="tables-content">
                        Dados da conta do usuário
                    </div>
                </div>-->
                <div id="manageTables-tab-block">
                    <div id="tables-content">
                        <TablesList />
                    </div>
                </div>
                <div id="manageUsers-tab-block" style="display: none">
                    <div id="tables-content">
                        <UserList :isAdmin="true"/>
                    </div>
                </div>
                <div id="manageCompanies-tab-block" style="display: none">
                    <div id="tables-content">
                        <ClientsList />
                    </div>
                </div>
                <div id="manageDashboards-tab-block" style="display: none">
                    <div id="tables-content">
                        <DashsList />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import TablesList from '../components/admin-dashboard/TablesList.vue'
import UserList from '../components/admin-dashboard/UsersList.vue'
import ClientsList from '../components/admin-dashboard/ClientsList.vue'
import DashsList from '../components/admin-dashboard/DashsList.vue'
export default {
    name:"AdminDashboard",
    data(){
        return{
            
        }
    },
    methods:{
        changeTabs(id){
            document.getElementsByClassName('is-active')[0].classList.remove('is-active');
            document.getElementById(id).classList.add('is-active');
            document.querySelectorAll('[id*="tab-block"]').forEach(function(e){
                e.style.display = 'none';
            });
            document.getElementById(id+"-tab-block").style.display = 'block';
        }
    },
    components: {
        NavBar,
        TablesList,
        UserList,
        ClientsList,
        DashsList
    }
}
</script>
<style scoped>
#tables-box{
    margin-top: 3%;
    font-size: 18px;
}
.tabs{
    font-size: 18px
}
th, td {
  padding: 5px;
  text-align: left;
}
#mainColumn{
    width: 45.7%
}
@media screen and (max-width: 1400px) {
    #mainColumn{
        width: 50%;
    }
}
</style>