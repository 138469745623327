<template>
    <div class="is-primary">
        <NavBar is-logged/>
        <div class="row"></div>
        <iframe :width="dashInfo.width" :height="dashInfo.height" :src="dashInfo.url" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
</template>
<script>
import NavBar from '../components/NavBar.vue';
import axios from 'axios';
export default {
    name:"Dashboard",
    created(){
        var config ={
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
        }
        axios.get("https://api.addata.me/dashboard/" + this.$route.params.pathMatch,config).then(res=>{
            this.dashInfo = res.data.dash[0];

        }).catch(err => {
            this.$router.push({name:'Home'})
            console.log(err);
        })
    },
    data(){
        return{
            dashInfo: {}
        }
    },
    components: {
        NavBar
    }
}
</script>