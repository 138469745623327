<template>
    <div class="hero is-fullheight is-light">
        <div id="login-box" class="columns is-centered">
            
            <form id="login-form" class="box column is-one-fifth">
                <div class="has-text-centered">
                    <img src="/pics/addata2.png" style="max-height: 10rem;">
                </div>
                <div v-if="err != undefined">
                    <div class="notification is-danger">
                        <!--<button class="delete"></button>-->
                        {{err}}
                    </div>
                </div>
                <div v-if="success != undefined">
                    <div class="notification is-success">
                        <!--<button class="delete"></button>-->
                        {{success}}
                    </div>
                </div>
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                    <input class="input" type="email" placeholder="seuemail@dominio.com" v-model="email">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Senha</label>
                    <div class="control">
                    <input class="input" type="password" placeholder="********" v-model="password">
                    </div>
                </div>
                <div class="field" @click="login">
                    <button class="button is-info">Entrar</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            email: '',
            password: '',
            err: undefined,
            success: undefined
        }
    },
    methods: {    
        login(){
            axios.post("https://api.addata.me/login",{
                "email": this.email,
                "password": this.password                
            }).then(res => {
                console.log(res.data);
                localStorage.setItem('token',res.data.token)
                var msg = "Login realizado";
                this.success = msg;
                this.$router.push({name: 'AdminDashboard'})
            }).catch(err => {
                try{    
                    var msg = err.response.data.err;
                    this.err = msg;
                }catch{
                    console.log(err)
                    this.err = "Servidor indisponível. Por favor tente novamente mais tarde."
                }
            })
        }
    },
    mounted: function(){
        document.getElementById('login-form').addEventListener("submit", function(event){
            event.preventDefault()
        });
    }
}
</script>
<style scoped>
#login-box{
    margin-top: 10rem;
}
</style>