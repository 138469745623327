<template>
    <div class="columns is-centered">
        <div class="column is-half">
            <div v-if="err != undefined">
                <div class="notification is-danger">
                    <!--<button class="delete"></button>-->
                    {{err}}
                </div>
            </div>
            <div class="field">
                <label class="label">Nome</label>
                <div class="control">
                <input class="input" type="text" placeholder="John Doe" v-model="name">
                </div>
            </div>
            <div class="field">
                <label class="label">Email</label>
                <div class="control">
                    <input class="input is-success" type="email" placeholder="youremail@domain.com" v-model="email">
                </div>
            </div>
            <div class="field">
                <label class="label">Senha</label>
                <div class="control">
                    <input class="input is-danger" type="password" placeholder="Your password" v-model="password">
                </div>
                <p class="help is-danger" style="display: none">This password is invalid</p>
            </div>           
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-link" @click="register">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            name: '',
            email: '',
            password: '',
            err: undefined
        }
    },
    methods: {    
        register(){
            axios.post("https://api.addata.me/user",{
                "name": this.name,
                "password": this.password,
                "email": this.email
            }).then(res => {
                console.log(res);
                this.$router.push({name: 'Home'})
            }).catch(err => {
                var msg = err.response.data.err;
                this.err = msg;
            })
        }
    }
}
</script>
<style scoped>

</style>