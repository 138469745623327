<template>
  <div>
    <nav class="navbar is-info" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/pics/simbolo_01_branco_300px.png" style="max-height: 3.85rem">
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <a class="navbar-item" href="/">
          Home
        </a>
        <div class="navbar-item has-dropdown is-hoverable" v-if="isLogged" v-on:mouseleave="hideDashs">
          <a class="navbar-link">
            Dashboards
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item client-item" v-for="client in clientsList" :key="client.id" :id="client.id" @click="showDashs(client.id, $event.target)">
              {{client.name}}
            </a>
            <div class="navbar-dropdown nested-dropdown is-hidden" id="DashList">
              <a class="navbar-item dash-item" v-for="dash in exhibitDashList" :key="dash.id" :href=" '/dashboard/' + dash.id">
                {{dash.name}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-end" v-if="!isLogged">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary">
              <strong>Criar conta</strong>
            </a>
            <router-link to="login">
              <a class="button is-light">
              Entrar
              </a>
            </router-link>
            
          </div>
        </div>
      </div>
      <div class="navbar-end" v-if="isLogged">
        <div class="navbar-item">
          <div class="buttons">
            <router-link to="login">
              <a class="button is-danger is-rounded" @click="LogOut">
              Sair
              </a>
            </router-link>
            
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'NavBar',
  props: {
    isLogged: Boolean
  },
  data(){
    return {
      dashList: [],
      exhibitDashList: [],
      clientsList: []
    }
  },
  created(){
    var config ={
            headers:{
                Authorization: "Bearer " + localStorage.getItem('token')
            }
    }
    axios.get("https://api.addata.me/dashboards/user/",config).then(res=>{
        this.dashList = res.data.dashs;
        this.clientsList = res.data.clients_list;

    }).catch(err => {
        console.log(err);
    })
  },
  methods:{
    showDashs(client_id, clicked_el){
      let clientItems = document.getElementsByClassName('client-item')
      let itemPosition = Array.from(clientItems).reverse().indexOf(clicked_el);
      this.exhibitDashList = this.dashList.filter(d => d.client_id == client_id)
      let el = document.getElementById('DashList');
      el.style.marginTop = (-32*itemPosition-44)+"px";
      el.classList.remove('is-hidden')
    },
    hideDashs(){
      let el = document.getElementById('DashList');
      el.classList.add('is-hidden')
    },
    LogOut(){
      localStorage.removeItem("token")
    }
  }
}
</script>
<style scoped>
.nested-dropdown{
  margin-left: 100%;
}
#DashList{
  border-top: 0px;
}
</style>