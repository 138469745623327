<template>
    <div class="columns hero is-centered">
        <h2>Edição de Usuário</h2>
        <hr>
        <div class="column is-half">
            <br>
            <div v-if="err != undefined">
                <div class="notification is-danger">
                    <!--<button class="delete"></button>-->
                    {{err}}
                </div>
            </div>
            <div class="field">
                <label class="label">Nome</label>
                <div class="control">
                <input class="input" type="text" placeholder="John Doe" v-model="name">
                </div>
            </div>
            <div class="field">
                <label class="label">Email</label>
                <div class="control">
                    <input class="input is-success" type="email" placeholder="youremail@domain.com" v-model="email">
                </div>
            </div>    
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-link" @click="update">Editar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created(){
        var req = {
            headers:{
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        }
        axios.post("https://api.addata.me/userById",{
            id: this.$route.params.id
         }, req).then(res =>{
            console.log(res);
            this.name = res.data.name;
            this.email = res.data.email;
            this.id = res.data.id;
        }).catch(err =>{
            console.log(err.response);
            alert(err.response.data);
            this.$router.push({name: 'Home'})
        })
    },
    data(){
        return{
            name: '',
            email: '',
            id: '',
            err: undefined
        }
    },
    methods: {    
        update(){
            var req = {
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }
            axios.put("https://api.addata.me/user",{
                name: this.name,
                email: this.email,
                id: this.id
            },req).then(res => {
                console.log(res);
                this.$router.push({name: 'Home'})
            }).catch(err => {
                var msg = err.response.data;
                this.err = msg;
            })
        }
    }
}
</script>
<style scoped>
h2{
    margin: 1%;
    text-align: center;
}
</style>