<template>
    <div>
        <div class="is-flex align-items is-justify-content-space-between">
            <div class="searchbox control has-icons-right">
                <input class="input" type="text" placeholder="Filtrar clientes" @keyup="queryClient($event.target.value)">
                <span class="icon is-small is-right">
                    <font-awesome-icon icon="search" />
                </span>
            </div>
            <button id="addClient" class="button is-primary" @click="showAddModal()">Adicionar dashboard</button>
        </div>
        <table class="table is-narrow">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Tipo</th>
                        <th>Data de criação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dash in exhibitDashs" :key="dash.id" :id="dash.id" >
                            <td>{{dash.id}}</td>
                            <td>{{dash.name}}</td>
                            <td>{{dash.dashboard_type}}</td>
                            <td>{{dash.created_at | processDate}}</td>
                            <td>
                                <button class="button is-success" @click="showEditModal(dash.id)"><font-awesome-icon icon="edit" /></button><button class="button is-danger" @click="showDeleteModal(dash.id)"><font-awesome-icon icon="trash-alt" /></button>
                            </td>
                    </tr>
                </tbody>
        </table>
        <nav class="pagination" role="navigation" aria-label="pagination">
            <ul class="pagination-list">
                <li v-for="page in pages" :key="page">
                    <a class="pagination-link" @click="updatePage(page)">{{page}}</a>
                </li>
            </ul>
        </nav>
        <div id="deleteDashModal" class="modal">
                    <div class="modal-background"></div>
                    <div class="modal-content">
                        <div class="card">
                            <header class="card-header">
                                <p class="card-header-title">
                                Deseja excluir esse dashboard?
                                </p>
                            </header>
                            <div class="card-content">
                                <div class="content">
                                    Confirme se deseja realmente excluir esse dashboard.
                                </div>
                            </div>
                            <footer class="card-footer">
                                <a href="javascript:void(0)" class="card-footer-item" @click="deleteDash">Excluir</a>
                                <a href="javascript:void(0)" class="card-footer-item" @click="hideDeleteModal">Cancelar</a>
                            </footer>
                            </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="hideDeleteModal"></button>
        </div>
        <div id="editDash" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Editando Cliente</p>
                <button class="delete" aria-label="close" @click="hideEditModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <div class="control">
                            <label class="label">Nome</label>
                            <input id="editDashName" class="input editable" type="text" placeholder="Nome do dashboard" v-model="editDash.name" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Tipo</label>
                        <div class="control">
                            <div class="select">
                                <select id="selectType" class="editable" v-model="editDash.dashboard_type" disabled>
                                    <option value="datastudio">datastudio</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Cliente</label>
                        <div class="control">
                            <div class="select">
                                <select id="selectClient" class="editable" v-model="editDash.client_id" disabled>
                                    <option v-for="client in activeClients" :key="client.id" :value="client.id">
                                        {{client.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">Largura</label>
                            <input id="editDashWidth" class="input editable" type="number" placeholder="1920" v-model="editDash.width" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">Altura</label>
                            <input id="editDashHeight" class="input editable" type="number" placeholder="1080" v-model="editDash.height" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">URL</label>
                            <input id="editDashUrl" class="input editable" type="text" placeholder="https://datastudio.google.com/embed/reporting/..." v-model="editDash.url" disabled>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button id="editDashButton" class="button is-warning" @click="startDraft">Editar</button>
                    <button id="saveDashButton" class="button is-success" @click="updateDash" style="display: none;">Salvar</button>
                    <button class="button" @click="hideEditModal">Cancel</button>
                </footer>
            </div>
        </div>
        <div id="addDashModal" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Adicionando Dashboard</p>
                <button class="delete" aria-label="close" @click="hideAddModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <div class="control">
                            <label class="label">Nome</label>
                            <input id="addDashName" class="input" type="text" placeholder="Nome do dashboard" v-model="addDash.name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Tipo</label>
                        <div class="control">
                            <div class="select">
                                <select id="selectType" v-model="addDash.dashboard_type">
                                    <option value="datastudio">datastudio</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Cliente</label>
                        <div class="control">
                            <div class="select">
                                <select id="selectClient" v-model="addDash.client_id">
                                    <option v-for="client in activeClients" :key="client.id" :value="client.id">
                                        {{client.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">Largura</label>
                            <input id="addDashWidth" class="input" type="number" placeholder="1920" v-model="addDash.width">
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">Altura</label>
                            <input id="addDashHeight" class="input" type="number" placeholder="1080" v-model="addDash.height">
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">URL</label>
                            <input id="addDashUrl" class="input" type="text" placeholder="https://datastudio.google.com/embed/reporting/..." v-model="addDash.url">
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="createDash">Salvar</button>
                    <button class="button" @click="hideAddModal">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created(){
        var config ={
            headers:{
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        }
        axios.get("https://api.addata.me/dashboard",config).then(res =>{
            //console.log(res)
            this.allDashs = res.data.dashs;
            this.pages = Math.ceil(this.allDashs.length / 10)
            this.updatePage(1);
        }).catch(err =>{
            console.log(err)
        })
        axios.get("https://api.addata.me/clients/active",config).then(res=>{
            this.activeClients = res.data.data;

        }).catch(err => {
            console.log(err);
        })
    },
    data(){
        return{
            activeClients: [],
            allDashs: [],
            exhibitDashs: [],
            pages: 1,
            currentPage: 1,
            addDash:{name: '', client_id: 1, dashboard_type: 'datastudio', width:Number , height:Number , url: ''},
            editDash: {},
            deleteDashId:0
        }
    },
    name: 'DashsList',
    methods:{
        showEditModal(id){
            var el = document.getElementById('editDash')
            el.classList.add("is-active");
            var dashs = this.exhibitDashs.filter(dash => dash.id == id);
            this.editDash = dashs[0];
            
        },
        hideEditModal(){
            var el = document.getElementById('editDash')
            el.classList.remove("is-active");
            let els = document.getElementsByClassName('editable');
            els.forEach(element => {
                element.disabled = true;
            });
            document.getElementById('editDashButton').style.display = 'flex';
            document.getElementById('saveClientButton').style.display = 'none';
            if(this.editDashRcds == null){
                document.getElementById('RcdsPrefix').classList.add("editable")
            }
            if(this.editDashGoals == null){
                document.getElementById('GoalsPrefix').classList.add("editable")
            }
            if(this.editDashFcb == null){
                document.getElementById('FBPrefix').classList.add("editable")
            }
        },
        showDeleteModal(id){
            var el = document.getElementById('deleteDashModal')
            el.classList.add("is-active");
            this.deleteDashId = id;
        },
        hideDeleteModal(){
            var el = document.getElementById('deleteDashModal')
            el.classList.remove("is-active");
        },
        startDraft(){
            let els = document.getElementsByClassName('editable');
            els.forEach(element => {
                element.disabled = false;
            });
            document.getElementById('editDashButton').style.display = 'none';
            document.getElementById('saveDashButton').style.display = 'flex';
        },
        showAddModal(){
            document.getElementById('addDashModal').classList.add("is-active");
        },
        hideAddModal(){
            document.getElementById('addDashModal').classList.remove("is-active");
            this.addClient = {name: '', fbpages: false, fbpost: false, igprofile: false, igposts: false, lkdpage: false, lkdposts: false, lkdvideos: false};
        },
        createDash(){
            var headers =  {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var dashData = this.addDash;
            axios.post("https://api.addata.me/dashboards",dashData,{
                headers: headers
            }).then(response =>{
                console.log(response.data)
                this.hideAddModal();
                axios.get("https://api.addata.me/dashboard",{
                    headers: headers
                }).then(res =>{
                    //console.log(res)
                    this.allDashs = res.data.dashs;
                    this.pages = Math.ceil(this.allDashs.length / 10)
                    this.updatePage(this.currentPage);
                }).catch(err =>{
                    console.log(err)
                })
            }).catch(err =>{
                console.log(err);
                this.hideAddModal();
            })
        },
        updateDash(){
            var headers =  {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var data = this.editDash;
            axios.post("https://api.addata.me/dashboards",data,{
                headers: headers
            }).then(response =>{
                console.log(response)
            }).catch(err =>{
                console.log(err)
            })
            this.hideEditModal();
        },
        deleteDash(){
            var config ={
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }
            axios.delete("https://api.addata.me/dashboards/" + this.deleteDashId,config).then(() =>{
                this.allDashs = this.allDashs.filter(c => c.id != this.deleteDashId);
                this.exhibitDashs = this.exhibitDashs.filter(c => c.id != this.deleteDashId);
                this.hideDeleteModal();
            }).catch(err =>{
                console.log(err)
            })
        },
        updatePage(pageNumber){
            var pageLinks = document.getElementsByClassName('pagination-link');
            pageLinks[this.currentPage -1].classList.remove('is-current');
            pageLinks[pageNumber -1].classList.add('is-current');
            this.currentPage = pageNumber;
            this.exhibitDashs = this.allDashs.slice((pageNumber * 10)-10,pageNumber * 10);
        },
        queryClient(query){
            if(query == ''){
                this.exhibitDashs = this.allDashs.slice((this.currentPage * 10)-10,this.currentPage * 10);
            } else{
                this.exhibitDashs = this.allDashs.filter(dash => dash.name.toLowerCase().includes(query.toLowerCase()))
            }
        }
    },
    props: {
        isAdmin: Boolean
    },
    filters: {
        processDate: function(date){
            return date.split('T')[0]
        }
    }
}
</script>
<style scoped>
table{
    margin-top: 3%;
    margin-left: 2%;
    width: 90%;
}
.searchbox{
    max-width: 45%;
    margin-left: 2%;
}
.pagination{
    margin-left: 2%;
}
#selectRole{   
    display: none;
}
.button.is-info{
    margin-top: 1rem;
}
.field .button.is-success{
    margin-top: 1rem;
}
#addUser{
    margin-left: 3%;
}
</style>