<template>
    <div>
        <table class="table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Cargo</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{user.name}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.role | processRole}}</td>
                            <td>
                                <button class="button is-success" @click="showEditModal(user.id)"><font-awesome-icon icon="user-edit" /></button><button class="button is-danger" @click="showDeleteModal(user.id)"><font-awesome-icon icon="trash-alt" /></button>
                            </td>
                        </tr>
                </tbody>
        </table>
        <div id="deleteModal" class="modal">
                    <div class="modal-background"></div>
                    <div class="modal-content">
                        <div class="card">
                            <header class="card-header">
                                <p class="card-header-title">
                                Deseja deletar esse usuário?
                                </p>
                            </header>
                            <div class="card-content">
                                <div class="content">
                                    Confirme se deseja realmente deletar esse usuário.
                                </div>
                            </div>
                            <footer class="card-footer">
                                <a href="javascript:void(0)" class="card-footer-item" @click="deleteUser">Deletar</a>
                                <a href="javascript:void(0)" class="card-footer-item" @click="hideDeleteModal">Cancelar</a>
                            </footer>
                            </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="hideDeleteModal"></button>
        </div>
        <div id="editModal" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Editando Usuário</p>
                <button class="delete" aria-label="close" @click="hideEditModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Nome</label>
                        <div class="control">
                            <input id="userName" class="input" type="text" placeholder="Text input" v-model="editUserName" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Empresa</label>
                        <div class="control">
                            <input id="userCompany" class="input" type="text" placeholder="Text input" v-model="editUserCompany" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control">
                            <input id="userEmail" class="input" type="email" placeholder="Email input" v-model="editUserEmail" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Senha</label>
                        <div class="control">
                            <input class="input" type="password" placeholder="*******" disabled>
                        </div>
                        <button id="resetButton" class="button is-info" @click="resetPassword">Resetar</button>
                    </div>
                    <div id="userRole" class="field">
                        <label class="label">Role</label>
                        <div class="control">
                            <input id="inputUserRole" class="input" type="text" :value="editUserRole"  v-parse disabled>
                        </div>
                    </div>
                    <div id="selectRoleBox" class="field">
                        <label class="label">Role</label>
                        <div class="control">
                            <div class="select">
                                <select id="selectRole" v-model="editUserRole">
                                    <option value="0">Colaborador</option>
                                    <option value="1">Administrador</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="level">
                        <div class="level-left">    
                            <label class="label">Acesso de Clientes</label>
                            <a @click="showClientsModal">
                                <font-awesome-icon :icon="['fa', 'external-link-alt']" />
                            </a>
                        </div>    
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button id="editUserButton" class="button is-warning" @click="startDraft">Editar</button> 
                    <button id="saveUserButton" class="button is-success" @click="updateUser" style="display: none;">Salvar</button>
                    <button class="button" @click="hideEditModal">Cancelar</button>
                </footer>
            </div>
        </div>
        <div id="addModal" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Convidando Novo Usuário</p>
                <button class="delete" aria-label="close" @click="hideAddModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Nome</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Nome" v-model="addUserName">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Empresa</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Empresa" v-model="addUserCompany" disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control">
                            <input class="input" type="email" placeholder="Email" v-model="addUserEmail">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Role</label>
                        <div class="control">
                            <div class="select">
                                <select name="UserRole" id="UserRole" v-model="addUserRole">
                                    <option value=0>Colaborador</option>
                                    <option value=1>Administrador</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-warning" @click="createUser">Enviar convite</button>
                    <button class="button" @click="hideAddModal">Cancel</button>
                    <div id="addUserMsg" class="notification is-danger is-hidden">
                        {{addUserMsg}}
                    </div>
                </footer>
            </div>
        </div>
        <div id="clientsModal" class="modal is-active" v-if="clientModalOn">
            <UserClientsList :allClients="clientsAccess" :userId="editUserId" :userName="editUserName" @closeAccessModal="hideClientsModal"/>
        </div>
        <button id="addUser" class="button is-primary" @click="showAddModal()" v-if="isAdmin">Adicionar usuário</button>
    </div>
</template>
<script>
import axios from 'axios';
import UserClientsList from './parts/UserClientsList.vue';
export default {
    created(){
        var config ={
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
        }
        axios.get("https://api.addata.me/users",config).then(res =>{
            //console.log(res)
            this.users = res.data;
        }).catch(err =>{
            console.log(err)
        })
    },
    data(){
        return{
            users: [],
            addUserName: "",
            addUserCompany: "Mais Clicks",
            addUserEmail: "",
            addUserRole: '0',
            addUserMsg: "Erro ao cadastrar usuário.",
            editUserName: "",
            editUserEmail: "",
            editUserRole: 0,
            editUserCompany: "",
            editUserId:0,
            deleteUserId: 0,
            clients: [],
            clientModalOn: false,
            clientsAccess: []
        }
    },
    name: 'UserList',
    methods:{
        showEditModal(id){
            var el = document.getElementById('editModal')
            el.classList.add("is-active");
            var user = this.users.filter((user)=>{ 
                return user.id == id;
            })
            this.editUserName = user[0].name;
            this.editUserEmail = user[0].email;
            this.editUserRole = user[0].role;
            this.editUserId = user[0].id;
            this.editUserCompany = user[0].company;
            
        },
        hideEditModal(){
            var el = document.getElementById('editModal')
            el.classList.remove("is-active");
            document.getElementById('userCompany').disabled = true;
            document.getElementById('selectRoleBox').style.display = "none";
            document.getElementById('userRole').style.display = "block";
            document.getElementById('editUserButton').style.display = 'flex';
            document.getElementById('saveUserButton').style.display = 'none';

        },
        showDeleteModal(id){
            var el = document.getElementById('deleteModal')
            el.classList.add("is-active");
            this.deleteUserId = id;
        },
        hideDeleteModal(){
            var el = document.getElementById('deleteModal')
            el.classList.remove("is-active");
        },
        startDraft(){
            document.getElementById('userCompany').disabled = false;
            document.getElementById('selectRoleBox').style.display = "block";
            document.getElementById('userRole').style.display = "none";
            document.getElementById('editUserButton').style.display = 'none';
            document.getElementById('saveUserButton').style.display = 'flex';
        },
        showAddModal(){
            document.getElementById('addModal').classList.add("is-active")
        },
        hideAddModal(){
            document.getElementById('addModal').classList.remove("is-active");
        },
        createUser(){
            var headers= {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var data = {     
                "name": this.addUserName,
                "email": this.addUserEmail,
                "company": this.addUserCompany,
                "role": this.addUserRole
            }
           axios.post("https://api.addata.me/users",data,{
               headers: headers
           }).then(response=>{
               console.log(response)
               this.addUserMsg = "Usuário cadastrado com sucesso";
               var el = document.getElementById('addUserMsg');
               el.classList.remove('is-hidden');
               el.classList.remove('is-danger');
               el.classList.add('is-success');
           }).catch(err=>{
               this.addUserMsg = err.response.data.err;
               var el = document.getElementById('addUserMsg');
               el.classList.remove('is-hidden');
           })
        },
        updateUser(){
            var headers= {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var data = {     
                "id": this.editUserId,
                "company": this.editUserCompany,
                "role": this.editUserRole
            }
           axios.put("https://api.addata.me/users",data,{
               headers: headers
           }).then(response=>{
               console.log(response.data)
               var config ={
                    headers:{
                        Authorization: "Bearer " + localStorage.getItem('token')
                    }
                }
                axios.get("https://api.addata.me/users",config).then(res =>{
                    //console.log(res)
                    this.users = res.data;
                    this.hideEditModal();
                }).catch(err =>{
                    console.log(err)
                    this.hideEditModal();
                })
           }).catch(err=>{
               console.log(err)
               this.hideEditModal();
           })
        },
        deleteUser(){
            var config ={
                    headers:{
                        Authorization: "Bearer " + localStorage.getItem('token')
                    }
                }
            axios.delete("https://api.addata.me/users/" + this.deleteUserId, config).then(()=>{
                this.hideDeleteModal();
                this.users = this.users.filter(u => u.id != this.deleteUserId);
            }).catch(err=>{
                console.log(err);
                this.hideModal();
            })
        },
        resetPassword(){
            axios.post("https://api.addata.me/resetpassword",{
                "email": this.editUserEmail
            }).then((response)=>{
                var el = document.getElementById('resetButton')
                el.classList.remove("is-info");
                el.classList.add("is-success");
                el.innerText = "Renovação de senha enviada";
                console.log(response.data)
            }).catch(err=>{
                var el = document.getElementById('resetButton')
                el.classList.remove("is-info");
                el.classList.add("is-danger");
                el.innerText = "Erro no servidor. Por favor tente novamente mais tarde.";
                console.log(err);
            })

        },
        showClientsModal(){
            var config ={
                headers:{
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }
            axios.get("https://api.addata.me/clients_acc/" + this.editUserId,config).then(res =>{
                this.clientsAccess = res.data.clientsResponse;
                this.clientModalOn = true;
            }).catch(err =>{
                console.log(err)
            })
            
        },
        hideClientsModal(){
            this.clientModalOn = false;
        }
    },
    components:{
        UserClientsList
    },
    props: {
        isAdmin: Boolean
    },
    filters: {
        processRole: function(value){
            if(value==0){
                return "Colaborador"
            }else if(value==1){
                return "Administrador"
            }
        }
    },
    directives: {
        parse: {
            // directive definition
            update: function (el) {
                if(el.value == 1){
                    el.value = "Administrador"
                }else{
                    el.value = "Colaborador"
                }
            }
        }
    }
}
</script>
<style scoped>
table{
    margin-left: 2%;
    width: 90%;
}
#selectRoleBox{   
    display: none;
}
.button.is-info{
    margin-top: 1rem;
}
.field .button.is-success{
    margin-top: 1rem;
}
#addUser{
    margin-left: 3%;
}
.level-left label{
    margin-right: 10px;
}
</style>