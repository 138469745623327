<template>
  <div class="home">
      <NavBar/>
      Bem-vindo ao addata app.
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
export default {
  name: 'Home',
  components: {
    NavBar
  }
}
</script>
