<template>
    <table class="tab-content table is-hoverable ">
        <tbody>
                <tr>
                    <td>FacebookPages</td>
                    <td>
                        <button id="fbpages" class="button is-info" @click="updateTable('fbpages')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>FacebookPosts</td>
                    <td>
                        <button id="fbposts" class="button is-info" @click="updateTable('fbposts')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>InstagramPosts</td>
                    <td>
                        <button id="igposts" class="button is-info" @click="updateTable('igposts')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>InstagramProfile</td>
                    <td>
                        <button id="igprofile" class="button is-info" @click="updateTable('igprofile')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>LinkedInPage</td>
                    <td>
                        <button id="lkdpage" class="button is-info" @click="updateTable('lkdpage')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>LinkedInPosts</td>
                    <td>
                        <button id="lkdposts" class="button is-info" @click="updateTable('lkdposts')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
                <tr>
                    <td>LinkedInVideos</td>
                    <td>
                        <button id="lkdvideos" class="button is-info" @click="updateTable('lkdvideos')"><font-awesome-icon icon="sync" /></button>
                    </td>
                </tr>
        </tbody>
    </table>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UpdateTables',
  methods:{
      updateTable(tableName){
            console.log("executando " + tableName);
            document.getElementById(tableName).innerText = 'Atualizando';
            document.getElementById(tableName).classList.remove('is-info');
            document.getElementById(tableName).classList.add('is-warning');
            var config = {
                method: 'post',
                url: 'https://api.addata.me/' + tableName
            };
            axios(config)
            .then(function () {
                //console.log(JSON.stringify(response.data));
                document.getElementById(tableName).innerText = 'Atualizado';
                document.getElementById(tableName).classList.remove('is-warning');
                document.getElementById(tableName).classList.add('is-success');
            })
            .catch(function (error) {
                console.log(error);
            });

        }
  }
}
</script>
<style scoped>
table{
    margin-left: 2%;
}
</style>