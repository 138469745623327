<template>
    <div>
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="card">
                <header class="card-header">
                        <p class="card-header-title">
                        Clientes Acessados - {{userName}}
                        </p>
                </header>
                <div class="card-content">
                    <div class="content align-items">
                        <div class="card-top is-flex align-items is-justify-content-space-between">
                            <div class="searchbox control has-icons-right">
                                <input class="input" type="text" placeholder="Filtrar clientes" @keyup="queryClient($event.target.value)">
                                <span class="icon is-small is-right">
                                    <font-awesome-icon icon="search" />
                                </span>
                            </div>
                            <div class="select-buttons">    
                                <button class="button is-info" @click="checkAll">Marcar todos</button>
                                <button class="button is-warning" @click="clearAll">Limpar</button>
                            </div>
                        </div>
                        <table class="table is-narrow">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="client in exhibitClients" :key="client.id" :id="client.id" >
                                            <td><input type="checkbox" class="checkbox client-checkbox" v-model="client.access" @change="alterClientList($event, client.id)">{{client.name}}</td>
                                            <td>{{client.id}}</td>
                                    </tr>
                                </tbody>
                        </table>
                        <nav class="pagination" role="navigation" aria-label="pagination">
                            <ul class="pagination-list">
                                <li v-for="page in pages" :key="page">
                                    <a class="pagination-link" @click="updatePage(page)">{{page}}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <footer class="modal-card-foot">
                    <button id="saveAccessButton" class="button is-success" @click="saveClientAccess">Salvar</button>
                    <button class="button" @click="closeAccessModal">Cancel</button>
                </footer>
            </div>    
        </div>
        <button class="modal-close is-large" aria-label="close" @click="closeAccessModal"></button>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    created(){
        this.pages = Math.ceil(this.allClients.length / 10)
        this.updatePage(1);
        let accessedClients = this.allClients.filter(c => c.access == true)
        accessedClients.forEach(client =>{
            this.selectedClients.push(client.id)
        })
    },
    data(){
        return{
            exhibitClients: [],
            pages: 1,
            currentPage: 1,
            selectedClients: []
        }
    },
    name: 'UserClientsList',
    methods:{
        updatePage(pageNumber){
            var pageLinks = document.getElementsByClassName('pagination-link');
            pageLinks[this.currentPage -1].classList.remove('is-current');
            pageLinks[pageNumber -1].classList.add('is-current');
            this.currentPage = pageNumber;
            this.exhibitClients = this.allClients.slice((pageNumber * 10)-10,pageNumber * 10);
        },
        queryClient(query){
            if(query == ''){
                this.exhibitClients = this.allClients.slice((this.currentPage * 10)-10,this.currentPage * 10);
            } else{
                this.exhibitClients = this.allClients.filter(client => client.name.toLowerCase().includes(query.toLowerCase()))
                //console.log(this.allClients)
            }
        },
        closeAccessModal(){
            this.$emit('closeAccessModal')
        },
        alterClientList(e,clientId){
            if(e.target.checked){
                this.selectedClients.push(clientId);
                
            }else{
                this.selectedClients = this.selectedClients.filter(c => c != clientId);
                
            }
        },
        saveClientAccess(){
            let saveAccessButton = document.getElementById('saveAccessButton')
            saveAccessButton.classList.remove("is-success");
            saveAccessButton.classList.add("is-warning");
            saveAccessButton.innerText = "Salvando";
            var headers= {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            var data ={
                        "id_user": this.userId,
                        "id_clients": this.selectedClients
                }
            axios.post("https://api.addata.me/clients_acc",data,{
               headers: headers
            }).then(() => {
                this.$emit('closeAccessModal')
                saveAccessButton.classList.add("is-success");
                saveAccessButton.classList.remove("is-warning");
                saveAccessButton.innerText = "Salvo";
            }).catch((err) =>{
                console.log(err);
                saveAccessButton.classList.remove("is-warning");
                saveAccessButton.classList.add("is-danger");
                saveAccessButton.innerText = "Erro ao salvar";
            })
        },
        checkAll(){
            let els = document.getElementsByClassName('checkbox')
            els.forEach(el => {
                el.checked = true
            })
            this.allClients.forEach(client =>{
                client.access = true
                this.selectedClients.push(client.id);
            })
        },
        clearAll(){
            let els = document.getElementsByClassName('checkbox')
            els.forEach(el => {
                el.checked = false
            })
            this.allClients.forEach(client =>{
                client.access = false
                this.selectedClients = [];
            })
        }
    },
    props: {
        isAdmin: Boolean,
        userId: Number,
        userName: String,
        allClients: Array

    },
    filters: {
        processDate: function(date){
            return date.split('T')[0]
        }
    }
}
</script>
<style scoped>
table{
    margin-top: 3%;
    margin-left: 2%;
    width: 90%;
}
.searchbox{
    max-width: 45%;
    margin-left: 2%;
}
.pagination{
    margin-left: -4%;
}
#selectRole{   
    display: none;
}
.client-checkbox{
    margin-right: 2%;
}
.select-buttons button{
    margin-right: 10px;
}
</style>